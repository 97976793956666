import React, { createContext, useContext, useState } from 'react'
import { Dialog } from '../layout'
import './Calendar.scss'

const context = createContext({})

export const useCalendar = () => useContext(context)

export const CalendarProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const openCalendar = () => setOpen(true)

  return (
    <context.Provider value={{ openCalendar }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div className="calendar">
          <div>
            <iframe src="https://meetings-eu1.hubspot.com/podandhive/product-demo" />
          </div>
        </div>
      </Dialog>
      {children}
    </context.Provider>
  )
}
