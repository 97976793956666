import React, { createContext, useContext, useEffect, useState } from 'react'
import { Navigation, NavigationMenu } from '.'
import { BookDemoButton, Logo } from '../widgets'
import './Header.scss'

const context = createContext({})

export const useHeader = () => useContext(context)

export const Header = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [visible, setVisible] = useState(false)
  const [delayVisible, setDelayVisible] = useState(false)

  const delayVisibility = () => {
    if(!visible) {
      setDelayVisible(true)
    }
  }

  const show = () => {
    setDelayVisible(false)
    setVisible(true)
  }

  useEffect(() => {
    document.fonts.ready.then(() => setVisible(true))
    setLoggedIn(typeof window === 'object' && !!window.localStorage.getItem('socket.authenticatedUser'))
  }, [])

  return <context.Provider value={{ delayVisibility, show }}>
    <header className={(visible && !delayVisible) ? 'visible' : undefined}>
      <div style={{ flexBasis: '33%' }}>
        <Logo />
        <NavigationMenu />
      </div>

      <div style={{ flexBasis: '33%', display: 'flex', justifyContent: 'center' }}>
        <Navigation />
      </div>

      <div style={{ flexBasis: '33%', display: 'flex', justifyContent: 'flex-end', gap: 8 }} className="buttons">
        {!loggedIn && <BookDemoButton />}
        <button
          className="login aqua"
          onClick={() => window.location.href = '/portal'}
        >
          {loggedIn ? 'Dashboard' : 'Log in'}
        </button>
      </div>
    </header>
    {children}
  </context.Provider>
}