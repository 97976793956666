import React, { useEffect, useState } from 'react'

export const Dialog = ({ open, onClose, children }) => {
  const [closed, setClosed] = useState(!open)
  const [visible, setVisible] = useState(open)

  useEffect(() => {
    if(open) {
      setClosed(false)
      setTimeout(() => setVisible(true), 20)
    } else {
      setVisible(false)
      setTimeout(() => setClosed(true), 550)
    }
  }, [open])

  return (!closed &&
    <div
      style={{
        opacity: visible ? 1 : 0,
        position: 'fixed',
        inset: 0,
        background: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: closed ? -1 : 1000,
        transition: 'opacity 0.5s'
      }}
      onClick={onClose}
    >
      <div
        style={{
          position: 'relative',
          border: '2px solid black',
          background: 'white',
          color: 'black',
          borderRadius: 20,
          overflow: 'hidden',
          boxShadow: '10px 10px 30px 0 rgba(0, 0, 0, 0.25)'
        }}
        onClick={(e) => e && e.stopPropagation()}
      >
        {children}
        <div
          style={{
            rotate: '45deg',
            position: 'absolute',
            top: 6,
            right: 12,
            fontSize: 32,
            cursor: 'pointer'
          }}
          onClick={onClose}
        >
          +
        </div>
      </div>
    </div>
  )
}